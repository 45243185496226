import { useState,useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { get_devices } from '../../api';
import Device from '../../components/device';
import { phoneState } from '../../store';


  const DevicesPage = () => {
    const [devices, setDevices]=useState([]);
    const phone=useRecoilValue(phoneState);
    const get_devices_data = async () => {
      const user={
        'user_phone':localStorage.getItem('phone'),
      }
      const response = await get_devices(user)
      console.log(response);
      let {code,data}=response
      if(code && code == 100){
        setDevices(data)
      }
    }

    useEffect(()=>{get_devices_data() },[]);

    return (
      <div className='w-100vw h-100vh pl-64'>
          <div className='h-full'></div>
      <div  className='w-full h-full grid grid-cols-3 mt-10  px-20'>
        {devices.length>0 &&  devices.map((device,index) => <Device data={device} key={index}  />)}
      </div>

      </div>

    );
  };

  export default DevicesPage;