import React,{useEffect,useState} from'react';
import { useRecoilValue } from 'recoil';
import { Table,Input,Pagination,Select,Space,DatePicker,Button} from 'antd';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import 'antd/dist/reset.css'
import { fmoney,get_devices_income,formatLocalDate } from '../../api';
import { phoneState,currateState } from '../../store';

const { RangePicker } = DatePicker;




const get_name = (str:string) => {
    if (str == null) return "";
    const index = str.indexOf("&");
    if (index === -1) {
      // 如果字符没有出现，返回整个字符串
      return str;
    } else {
      // 截取从字符串开始到字符出现位置的部分
      return str.slice(0, index);
    }
  }


const SummaryPage:React.FC = () => {
    const curRate = useRecoilValue(currateState);
    const [orders,setOrders]:any[]=useState([]);
    const phone = useRecoilValue(phoneState);
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

    const columns = [
        { title: 'Device SN', dataIndex: 'imei', key: 'imei' },
        {title:'Devcie Name',dataIndex:'goods_name',key:'goods_name',render:(text:string)=>(get_name(text)) },
        {title:'Devcie Type',dataIndex:'goods_category_name',key:'goods_category_name',render:(text:string)=>(text== null?"":(text=="烘干机"?"Dryer":"Washing Machine")) },
        {title:'Amount(USD)',dataIndex:'amount',key:'amount',render:(text:number)=>(text/curRate).toFixed(2)},
        {title:'Orders',dataIndex:'order_qty',key:'order_qty'},

      ];

    const get_date= (n:number,start:string='N')=>{
        // const timestamp = start === "Y" ? n+ 60*60*24*1000 : n;
        const timestamp = n+ 60*60*24*1000
        const date = new Date(timestamp);
        const dateString = date.toISOString().slice(0, 10);
        return dateString;
      }


    const onDateChange = (value: any) => {
        if (value == null) return
        if (value[0] && value[1]) {
          setDateRange({ startDate: value[0], endDate: value[1] });
        }
      };

      const get_data=async () => {
        let resdata:any[] = []
        const today = new Date();
        // const lastMonthFirstDay= new Date(today.getFullYear(), today.getMonth()-1, 1);
        // const lastMonthLastDay= new Date(today.getFullYear(), today.getMonth(), 0);
        const first_day = new Date(today.getFullYear(), today.getMonth(), 1);
          const params=
              {
                  'user_phone':localStorage.getItem('phone'),
                  's_date':dateRange.startDate == null ?  formatLocalDate(first_day):get_date(dateRange.startDate,'Y'),
                  'e_date':dateRange.endDate == null?  formatLocalDate(today):get_date(dateRange.endDate,'N'),
                }

          let res=await get_devices_income(params);
          console.log(params.s_date+"-" + params.e_date);
          let {code,data}=res;

          if(code && code ==100){
            const totalAmount = data.reduce((sum:number, row:any) => sum + Number(row["amount"]), 0);
            const totalQty = data.reduce((sum:number, row:any) => sum + Number(row["order_qty"]), 0);
            resdata =[
            {
              imei:`Order Amount`,
              goods_name:null,
              goods_category_name:null,
              amount:totalAmount,
              order_qty:totalQty,

            },...data]
             setOrders(resdata)
          }

      }

      useEffect(()=>{get_data() },[]);

    return (
        <div className='w-100vw h-100vh pl-64'>
             <div className='p-4  justify-normal space-x-2'>
                <RangePicker
                onChange={onDateChange}

                />
                <Button   type="primary" onClick={get_data} >Query</Button>
             </div>
             <Table
        dataSource={orders}
        columns={columns}
        pagination={false}
         />

        </div>
    )
}

export default SummaryPage;