const config = {
    apiUrl:'https://api.vplusvn.net:28899',
    accessToken: 'YOUR_ACCESS_TOKEN'
}

export function fetchPost(url: string, postData: Record<string, any>, customHeader: Record<string, string> = {}) {
    let formData = new FormData();

    if (postData) {
        for (let key in postData) {
            if (typeof postData[key] === 'string' || typeof postData[key] === 'number' || typeof postData[key] === 'boolean' || postData[key] instanceof Blob) {
                formData.append(key, postData[key]);
            } else {
                console.warn(`Unsupported data type for key '${key}':`, postData[key]);
            }
        }
    }

    const request = fetch(`${config.apiUrl}${url}`, {
        method: 'POST',
        body: formData,
        mode: 'cors',
        headers: new Headers({
            'Accept': 'application/json',
            ...customHeader
        })
    });

    return fetchResult(request);
}

export function fetchGet(url: string, params?: Record<string, string | number | boolean>, header?: Record<string, string>) {
    header = header ?? {}; // 使用空值合并运算符简化空对象检查

    if (params) {
        const paramsArray = Object.entries(params)
            .map(([key, value]) => `${key}=${encodeURIComponent(value.toString())}`); // 使用map和模板字符串，并确保value是字符串

        if (paramsArray.length > 0) {
            url += url.includes('?') ? '&' : '?'; // 使用includes简化检查
            url += paramsArray.join('&');
        }
    }

    const requestHeaders = new Headers({
        'Accept': 'application/json',
        // 移除了不适当的Content-Type头部
        ...header
    });

    const request = fetch(`${config.apiUrl}${url}`, {
        method: 'GET',
        mode: 'cors',
        headers: requestHeaders
    });

    return fetchResult(request); // 假设这是一个处理fetch返回值的辅助函数
}


// 注意：fetchResult 函数需要额外处理 fetch API 的错误响应
function fetchResult(request: Promise<Response>) {
    return request.then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
    }).catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    });
}