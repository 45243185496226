import React,{useState,useEffect} from'react';
import { useRecoilValue,useRecoilState } from 'recoil';
import { Col, Row, Statistic } from 'antd';
import { Card, Space,Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import ReactEcharts from "echarts-for-react"
import { useNavigate } from "react-router";
import { login,get_order_amount,get_income,formatDate,formatLocalDate,get_exchange_rate,get_devices_income,get_history_income,get_monthly_income } from '../api';
import { loginState,phoneState,sidebarState,currateState } from '../store';
import { format } from 'path';

interface Mon{
  years:number,
  months:number,
  mon_amount:number,
  mon_orders:number,
  mon_users:number
}

const Mon_titel=['January','February','March','April','May','June','July','August','September','October','November','December']

const Home = () => {
  const navigate = useNavigate();
  const islogin= useRecoilValue(loginState);
  const phone =useRecoilValue(phoneState)
  const [exchange_rate,setExchange_rate]=useRecoilState(currateState);
  const [datelist,setDatelist]=useState([]);
  const [valuelist,setValueList]=useState([]);
  const [orderlist,setOrderlist]=useState([]);
  const [income,setIncome]=useState([]);
  const [items,setItems]=useState([]);
  const [mon,setMon]=useState("");
  // const items: MenuProps['items'] = [];
  const [monincome,setMonIncome]=useState({
    'years':0,
    'months':0,
    'mon_amount':0,
    'mon_orders':0,
    'mon_users':0
  });
  const [lastincome,setLastIncome]=useState({
    'years':0,
    'months':0,
    'mon_amount':0,
    'mon_orders':0,
    'mon_users':0
  });

  const [totdata,setTotdata]=useState({
    'month_qty':0,
    'today_qty':0,
    'yesterday_qty':0,
    'month_amount':0,
    'today_amount':0,
    'yesterday_amount':0,

  });
  const [sidebaropen, setSidebarOpen]=useRecoilState(sidebarState);

  const get_mon_data = async (m:number) => {
    setMon("")
    setMon(Mon_titel[m-1])


    const bodydata={
      'user_phone':localStorage.getItem('phone'),
      'years':2024,
      'months':m
    }
    const response = await get_monthly_income(bodydata);
    let {code,data}=response

    if(code == 100){
      setMonIncome(data[0]);

    }





    // for(let i=0;i<income.length;i++){
    //   if(income[i]["years"]==2024 && income[i]["months"]==m){
    //     setMonIncome(income[i])
    //     console.log('item:',income[i])
    //     break
    //   }
    // }

  }



  const get_orders_data = async () => {
    const data={
      'user_phone':localStorage.getItem('phone'),
    }
    const response = await get_order_amount(data)
    setTotdata(response.data)
    // console.log(response);

  }

  const get_income_data = async () => {

    const today = new Date();
    let daysago = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
    const data={
      'user_phone':localStorage.getItem('phone'),
      's_date':formatLocalDate(daysago),
      'e_date':formatLocalDate(today)
    }
    const response = await get_income(data)
    console.log(response);




  }






  const get_curRate= async () => {
    try {
      const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // console.log(data["rates"]["VND"]);
      setExchange_rate(data["rates"]["VND"])
      return data["rates"]["VND"]
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }

  }

  const history_device_income = async () => {
    const today = new Date();
    const rate:any = await(get_curRate())
    let months=today.getMonth();
    const menuData:any=[]
    const data={
      'user_phone':localStorage.getItem('phone'),
    }
    try {
      const response = await get_history_income(data);
      for(let i=0;i<response.data.length;i++){
        menuData.push({
          key: i,
          label: (
            <p onClick={() => get_mon_data(response.data[i]["months"])}>{Mon_titel[i]}</p>
        )

        })
      }
      setItems(menuData.sort((a:any, b:any) => a.key - b.key));
      setIncome(response.data);
      const sort_data:any = response.data.sort((a:any, b:any) => a.months - b.months)

      setDatelist(sort_data.map(function (item:any) {
        return Mon_titel[item['months']-1] ;
      }))


      setValueList(sort_data.map(function (item:any) {
        return item['mon_amount']/rate*0.6>250?(item['mon_amount']/rate*0.6).toFixed(2):250;
      }))


      setOrderlist(sort_data.map(function (item:any) {
        return (item['mon_amount']/rate).toFixed(2);
      }))

      sort_data.forEach((item:any) => {
        if(item.years==2024 && item.months==months ){
          // console.log('item:',item)
          setLastIncome(item);
          setMonIncome(item);
        }
      })
       get_mon_data(months);
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }

  }

  const get_data=async()=>{
        await get_curRate()
      await get_orders_data();
      // await get_income_data();
      await history_device_income();



  }


  useEffect(()=>{
    setSidebarOpen(true)
    if(localStorage.getItem('isLogin')){
      get_data();
    }
    else{
      navigate('/')
    }

  },[])


  const option = {
    visualMap: [
      {
        show: false,
        type: 'continuous',
        seriesIndex: 0,
        min: 0,
        max: 400
      },
      {
        show: false,
        type: 'continuous',
        seriesIndex: 1,
        dimension: 0,
        min: 0,
        max: datelist.length - 1
      }
    ],

    title: [
      {
        left: 'center',
        text: 'Revenue shared to Dualmint'
      },
      {
        top: '55%',
        left: 'center',
        text: 'History Revenue'
      }
    ],
    tooltip: {
      trigger: 'axis'
    },
    xAxis: [
      {
        data: datelist
      },
      {
        data: datelist,
        gridIndex: 1
      }
    ],
    yAxis: [
      {},
      {
        gridIndex: 1
      }
    ],
    grid: [
      {
        bottom: '60%'
      },
      {
        top: '60%'
      }
    ],
    series: [
      {
        type: 'line',
        showSymbol: false,
        data: valuelist
      },
      {
        type: 'line',
        showSymbol: false,
        data: orderlist,
        xAxisIndex: 1,
        yAxisIndex: 1
      }
    ]
};



  return (
    <div className='w-100vw h-100vh relative left-64 mt-10 ml-4'>
      <div className='flex'>
      <Space direction="horizontal" size={16}>
      <Card onClick={()=>false}   title={
         <div>History Revenue
          <div>
          <Dropdown menu={{ items }}   >
             <a >
              <Space>
                {mon}
                  <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          </div>
          </div>} extra={<a href="#"></a>} style={{ width: 300 }} hoverable={true}>
        <Row gutter={8}>
        <Col span={10}>
          <Statistic title={<div className='flex-col items-center'><p>Revenue Shared</p><p>to Dualmint</p></div>} value={monincome.mon_amount==null?0:monincome.mon_amount/exchange_rate*0.6>250?(monincome.mon_amount/exchange_rate*0.6).toFixed(2):250} />
          <p></p>
       </Col>
        <Col span={6}>
          <Statistic title={<div className='flex-col items-center'><p>&nbsp;</p><p>Orders</p></div>} value={monincome.mon_orders   == null?0:monincome.mon_orders} />
          <p></p>
       </Col>
       <Col span={8}>
          <Statistic title={<div className='flex-col items-center'><p>Total</p><p>Amount(USD)</p></div>} value={monincome.mon_amount==null?0:(monincome.mon_amount/exchange_rate).toFixed(2)} />
          <p></p>
       </Col>
        </Row>

      </Card>
      <Card title={<div>Current Revenue<div></div> </div>} extra={<a href="#"></a>} style={{ width: 300 }} hoverable={true}>
        <Row gutter={8}>
        <Col span={8}>
          <Statistic title={<div className='flex-col items-center'><p></p><p>Orders</p></div>} value={totdata.month_qty==null?0:totdata.month_qty} />
          <p>{(totdata.month_qty==null?0:totdata.month_qty/lastincome.mon_orders*100).toFixed(2)}%</p>
       </Col>
       <Col span={8}>
          {/* <Statistic title={<div className='flex-col items-center'><p>&nbsp;</p><p></p></div>} value={""} /> */}
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>MoM</p>
       </Col>
       <Col span={8}>
          <Statistic title={<div className='flex-col items-center'><p></p><p>Amount(USD)</p></div>} value={totdata.month_amount==null?0:(totdata.month_amount/exchange_rate).toFixed(2)} />
          <p>{totdata.month_amount==null?0:(totdata.month_amount/lastincome.mon_amount*100).toFixed(2)}%</p>
       </Col>
        </Row>
      </Card>
      <Card title="Today" extra={<a href="#"></a>} style={{ width: 300 }} hoverable={true}>
        <Row gutter={16}>
        <Col span={12}>
          <Statistic title={<div className='flex-col items-center'><p></p><p>Orders</p></div>} value={totdata.today_qty==null?0:totdata.today_qty} />
          <p>&nbsp;</p>
       </Col>
       <Col span={12}>
          <Statistic title={<div className='flex-col items-center'><p></p><p>Amount(USD)</p></div>} value={totdata.today_amount==null?0:(totdata.today_amount/exchange_rate).toFixed(2)} />
          <p>&nbsp;</p>
       </Col>
        </Row>
      </Card>
      <Card title="MAU(Monthly Active User)" extra={<a href="#"></a>} style={{ width: 300 }} hoverable={true}>
        <Row gutter={8}>
        <Col span={6}>
          {/* <Statistic title="Locations" value={monincome.mon_orders==null?0:monincome.mon_orders} /> */}
          <p>&nbsp;</p>
          <p className='text-gray-500'>Location</p>
          <p className=' text-[20px]'>{mon}</p>
       </Col>
       <Col span={8}>
          <Statistic title={<div className='flex-col items-center'><p>&nbsp;</p><p>Users</p></div>} value={monincome.mon_users==null?0:monincome.mon_users} />

       </Col>
       <Col span={10}>
          <Statistic title={<div className='flex-col items-center'><p>Average Order</p><p>Amount</p></div>} value={monincome.mon_orders==null?0:(monincome.mon_amount/monincome.mon_orders/exchange_rate).toFixed(2)} />

       </Col>
        </Row>
      </Card>


    </Space>
    </div>
    <div className='w-full h-full flex pt-10'>
    <ReactEcharts option={option} style={{ height: '800px', width: '100%' }}
      className={'react_for_echarts'} />

    </div>
    </div>

  )



}

export default Home;