import React, { useState,useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from "react-router";
import { MobileOutlined,LockOutlined } from '@ant-design/icons';
import { Input,Button } from 'antd';
import backimg from './back.jpg'
import { login } from '../api';
import { loginState,phoneState,sidebarState } from '../store';


const LoginPage = () => {
  const navigate = useNavigate();
  const [phone,setPhone]=useRecoilState(phoneState);
  const [password,setPassword]=useState('');
  const [isLogin,setLogin] = useRecoilState(loginState);
  const [isError,setError]=useState(false)
  const [sidebar,setSidebar]=useRecoilState(sidebarState)

  const handleLogin = async () => {
    const data={
      'user_phone':phone,
      'user_pass':password
    }
    try {
      const response = await login(data);
      let {code} = response
      if (code === 200) {
        setLogin(true);
        setPhone(response.data[0]['user_phone'])
        localStorage.setItem('phone',response.data[0]['user_phone'])
        localStorage.setItem('isLogin',JSON.stringify(true));
        navigate('/home');
      } else {
        setError(true);
        setPhone('');
        setPassword('');
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      setError(true)
    }
  };

  useEffect(()=>{
    setSidebar(false);
    localStorage.setItem('isLogin',JSON.stringify(false));
  },[])

  return (
    <div style={{
      backgroundImage: `url(${backimg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh', // 设置高度为视口高度
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px',
      boxSizing: 'border-box',
    }}>
      {/* <img src={logo} alt='logo' className='w-full h-full' /> */}
      <div className='w-25vw h-40vh shadow-2xl pt-4'>
        <h1 className='text-white text-2xl font-bold'>Welcome!</h1>
        <p >Merchant Management</p>
        <p className='text-[10px]'>New Opportunities for Shared Business</p>
        <div className='w-full flex flex-col items-center justify-center pt-4'>
          <div className='w-1/2 h-full  flex-col justify-between '>
           <Input
           size="middle"
           placeholder=""
           prefix={<MobileOutlined className='text-blue-800' />}
           onChange={(e:any) => {
            setPhone(e.target.value);
            setError(false);
          }}
           value={phone}
           className='mb-4'
           />
           <Input
           size="middle"
           placeholder=""
           prefix={<LockOutlined />}
           type='password'
           value={password}
           onChange={(e:any) => setPassword(e.target.value)}
           />

          </div>
          {isError && <p className='text-[14px] py-2 text-red-600 font-semibold'>Check the phone number and password</p>}

          <div className='w-1/2 flex justify-between pt-4 pb-2 '>
            <Button type="primary" block onClick={() => handleLogin()}>
              Login
            </Button>
          </div>


        </div>


      </div>

    </div>
  );
};

export default LoginPage;