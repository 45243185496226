import React from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from "react-router";
import { sidebarState } from '../store';

const Sidebar = () => {
  const sidebaropen = useRecoilValue(sidebarState);
  const navigate = useNavigate();
  return (
    <>
    { sidebaropen &&
    <div className="w-64 fixed inset-0 z-40 flex flex-wrap">
      <div className="w-64 bg-[#3563fc] text-white flex flex-col">
        <div className="flex items-center h-16 pl-4 justify-center">
          <span className="text-xl font-semibold">Vplus Partner</span>
        </div>
        <nav className="flex-1 px-4 space-y-4">
          <a href="#" className="block px-4 py-1 text-white rounded hover:bg-gray-700" onClick={() => navigate('/home')}>Home</a>
          <a href="#" className="block px-4 py-1 text-white rounded hover:bg-gray-700" onClick={() => navigate('/device')}>Device</a>
          <a
          href="#"
          className="block px-4 py-1 text-white rounded hover:bg-gray-700"
          onClick={() => navigate('/order')}
          >Orders</a>
           <a
          href="#"
          className="block px-4 py-1 text-white rounded hover:bg-gray-700"
          onClick={() => navigate('/sum')}
          >Statistics</a>
          <a href="#" className="block px-4 py-1 text-white rounded hover:bg-gray-700">About</a>
          <a href="#" className="block px-4 py-1 text-white rounded hover:bg-gray-700" onClick={() => navigate('/')}>Exit</a>
        </nav>
      </div>
    </div>
}
  </>
  );
};

export default Sidebar;