import { RecoilState,atom } from "recoil";

export const loginState: RecoilState<boolean> = atom({
  key: "loginState",
  default: false,
});

export const phoneState: RecoilState<string> = atom({
  key: "phoneStated",
  default: "",
});

export const sidebarState: RecoilState<boolean> = atom({
  key: "sidebarState",
  default: true,
});

export const currateState: RecoilState<number> = atom({
  key: "currateState",
  default: 0,
})