
import { fmoney } from "../api"
import { CgSmartHomeWashMachine } from "react-icons/cg";

//定义interface
interface Props {
  data:{
  imei:string,
  goods_name:string,
  org_id:string,
  org_name:string,
  goods_category_name:string,
  mon_amount?:number,
  mon_orders?:number,
  today_amount?:number,
  today_orders?:number,
  yesterday_amount?:number,
  yesterday_orders?:number,
  }
}

const Device = (props:Props) => {
  const get_name = (str:string) => {
    const index = str.indexOf("&");
    if (index === -1) {
      // 如果字符没有出现，返回整个字符串
      return str;
    } else {
      // 截取从字符串开始到字符出现位置的部分
      return str.slice(0, index);
    }
  }
  return (
    <div className='w-80 h-40 bg-[#f4f4f4] rounded-lg shadow-md my-4 border-l-2 border-r-2 border-t-2'>
        <div className='w-full h-1/2 flex justify-center items-center '>
          <div className="w-full pt-2 flex flex-col  items-center justify-center">
            <h1 className="text-[#999] font-bold">{get_name( props.data.goods_name)}</h1>
            <p className="text-[#999] text-xs ">{props.data.imei}</p>
          </div>
          <div className="pr-4">
          <CgSmartHomeWashMachine
              className={
                props.data.goods_category_name == "烘干机"
                  ? "text-[#f02412] text-[32px]"
                  : "text-[#1890ff] text-[32px]"
              }
            />

          </div>

        </div>
        <div  className='w-full h-1/2 flex justify-between items-center text-text-rgba border-2'>
            <div className='w-1/3'>
                <p className="text-[#888] pt-4" >Month({props.data.mon_orders})</p>
                <p className="text-[#444] font-semibold">+ {fmoney(props.data.mon_amount?.toString()||'0',0)}</p>
            </div>
            <div className='w-1/3 h-full  border-l-2 border-r-2 flex flex-col justify-center items-center'>
                <p className="text-[#888] pt-4">Today({props.data.today_orders})</p>
                <p className="text-[#444] font-semibold">+ {fmoney(props.data.today_amount?.toString()||'0',0)}</p>
            </div>
            <div className='w-1/3 '>
                <p className="text-[#888] pt-4">Yesterday({props.data.yesterday_orders})</p>
                <p className="text-[#444] font-semibold">+{fmoney(props.data.yesterday_amount?.toString()||'0',0)}</p>
            </div>


        </div>

    </div>
  );
};

export default Device;