import React, { useState,useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Table,Input,Pagination,Select,Space,DatePicker,Button} from 'antd';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import 'antd/dist/reset.css'
import { get_order,formatDate,fmoney,formatLocalDate } from '../../api';
import { phoneState,currateState } from '../../store';

const get_name = (str:string) => {
  const index = str.indexOf("&");
  if (index === -1) {
    // 如果字符没有出现，返回整个字符串
    return str;
  } else {
    // 截取从字符串开始到字符出现位置的部分
    return str.slice(0, index);
  }
}





  const { RangePicker } = DatePicker;

  const OrdersPage:React.FC = () => {
    const curRate = useRecoilValue(currateState);
    const [orders,setOrders]:any[]=useState([]);
    const phone = useRecoilValue(phoneState);
    const [pageNum,setPageNum]=useState(1);
    const [pageSize,setPageSize]=useState(10);
    const [active,setActive]=useState(0);
    const [total,setTotal]=useState(0)
    const { Search } = Input;

    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

    const columns = [
      { title: 'Order ID', dataIndex: 'order_no', key: 'order_no' },
      { title: 'Payment Date', dataIndex: 'pay_time', key: 'pay_time' ,render: (text:any) =>formatDate(text),sorter: (a:any, b:any) => a.pay_time - b.pay_time, },
      { title: 'Service Name', dataIndex: 'sku_name', key: 'sku_name' },
      {title:'Amount(VND)',dataIndex:'order_amount',key:'order_amount',render:(text:any)=>fmoney(text,0)},
      {title:'Devcie SN',dataIndex:'imei',key:'imei'},
      {title:'Devcie Name',dataIndex:'goods_name',key:'goods_name',render: (text:any) =>get_name(text) },
      {title:'Store Name',dataIndex:'org_name',key:'org_name'},


    ];

    const onDateChange = (value: any) => {
      if (value == null) return
      if (value[0] && value[1]) {
        setDateRange({ startDate: value[0], endDate: value[1] });
      }
    };

      // 分页改变时的回调函数
  const handleTableChange = (pagination:any, filters:any, sorter:any) => {
    const { currentPage, pageSize } = pagination;
    setPageNum(currentPage);
    setPageSize(pageSize);
  };

    const handleSearch = (value:any) => {
      console.log('Search:', value);
    };

    const get_date= (n:number,start:string='N')=>{
      // const timestamp = start === "Y" ? n+ 60*60*24*1000 : n;
      const timestamp = n+ 60*60*24*1000
      const date = new Date(timestamp);
      const dateString = date.toISOString().slice(0, 10);
      return dateString;
    }




    const get_data=async () => {
      let resdata:any[]=[];
      const today = new Date();
      // const lastMonthFirstDay= new Date(today.getFullYear(), today.getMonth()-1, 1);
      // const lastMonthLastDay= new Date(today.getFullYear(), today.getMonth(), 0);
      const first_day = new Date(today.getFullYear(), today.getMonth(), 1);
        const params=
            {
                'user_phone':localStorage.getItem('phone'),
                's_date':dateRange.startDate == null ?  formatLocalDate(first_day):get_date(dateRange.startDate,'Y'),
                'e_date':dateRange.endDate == null?  formatLocalDate(today):get_date(dateRange.endDate,'N'),
              }

        let res=await get_order(params);
        console.log(params.s_date+"-" + params.e_date);
        let {code,data}=res;

        if(code && code ==100){
          const totalAmount = data.reduce((sum:number, row:any) => sum + row["order_amount"], 0);
          resdata= [{
            order_no:`Order Amount:`+(data.length),
            pay_time:null,
            sku_name:"",
            order_amount:(totalAmount/curRate).toFixed(2),
            imei:null,
            goods_name:'',
            org_name:null


          },...data]
           setOrders(resdata)
            setTotal(data.length)
        }


    }

    const handleChange = (value: string) => {
      console.log(`selected ${value}`);
    };

    function exportToExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('My Sheet');

      worksheet.addRow(['Order ID','Payment Date','Service Name','Amount(VND)','Devcie SN','Devcie Name','Store Name']);
      orders.forEach((order:any) => {
        worksheet.addRow([order.order_no,order.pay_time,order.sku_name,order.order_amount,order.imei,order.goods_name,order.org_name]);
      });
      // 设置列宽、表头、数据等
      // ...
      workbook.xlsx
        .writeBuffer()
        .then(data => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'filename.xlsx');
        });
    }


    useEffect(()=>{get_data() },[]);

    return (
      <div className='w-100vw h-100vh pl-64'>

        {/* <Search placeholder="Search name" onSearch={handleSearch} /> */}
        <div className='p-4  justify-normal space-x-2'>
        <RangePicker
          onChange={onDateChange}

        />
        <Button   type="primary" onClick={get_data} >Query</Button>
        <Button   type="primary" onClick={exportToExcel} >export To Excel</Button>


        </div>



        <Table
        dataSource={orders}
        columns={columns}
        onChange={handleTableChange}
        pagination={false}
         />
            {/* <Pagination
          current={pageNum}
          pageSize={pageSize}
          total={total}
          showSizeChanger
          showQuickJumper

        /> */}

      </div>

    );
  };

  export default OrdersPage;

