import React from 'react';
import { RecoilRoot,useRecoilValue } from 'recoil';
import {  Routes,Route } from 'react-router-dom';
import {BrowserRouter,HashRouter,Link} from 'react-router-dom'
import './App.css';
import { sidebarState } from './store';
import Home from './pages/home';
import Sidebar from './components/sidebar';
import OrdersPage from './pages/orders';
import DevicesPage from './pages/devices';
import LoginPage from './pages/login';
import SummaryPage from './pages/summary';
import Test from './pages/test';



function App() {
  return (
      <RecoilRoot>
        <div className="App">
        { <Sidebar />}
            <Routes>
              <Route  path="/"  element={<LoginPage></LoginPage>} caseSensitive></Route>
              <Route  path="/home"  element={<Home></Home>} ></Route>
              <Route  path="/device"  element={<DevicesPage></DevicesPage>} ></Route>
              <Route  path="/order"  element={<OrdersPage></OrdersPage>} ></Route>
              <Route  path="/sum"  element={<SummaryPage></SummaryPage>} ></Route>
            </Routes>
        </div>
      </RecoilRoot>

  );
}
export default App;
