import { fetchGet, fetchPost } from "./fetchData";

export const get_orderlist =async (data:string) => {
  const response = await fetchGet(`/api/orders/weekorder/${data}`)
  return response

}

//逗号格式化数字
export const fmoney = (s: string, n: number) => {
  if (s === "") return;
  if (s === undefined) return;
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  var l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1];
  var t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
  }
  return t.split("").reverse().join("");
};

export const formatDate = (date: string) => {
  if (date == null)
    return "";
  const currentDate = new Date(date);
  // 获取年、月、日、小时、分钟和秒
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  // 格式化日期和时间
  const formattedDateTime = `${year}-${month}-${day}`;

  return formattedDateTime;
};


export const formatLocalDate=(date: Date)=>{
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() 返回的月份是从0开始的
  const day = date.getDate();

  // 使用padStart确保月和日始终有两位数字
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
}

//登入验证data={user_phone:string,user_pass:string}
export const login= async (data:any) => {
  const response = await fetchPost('/api/login', data);
  return response
}

//设备例表 data={user_phone:string}
export const get_devices=async (data:any) => {
  const response = await fetchPost('/api/devices/get_devices', data);
  return response
}

//订单例表 data={user_phone:string,s_date:string,e_date:string}
export const get_order=async (data:any) => {
  const response = await fetchPost('/api/devices/get_orders', data);
  return response
}

//订单金额统计 data={user_phone:string}
export const get_order_amount=async (data:any) => {
  const response = await fetchPost('/api/devices/get_amount', data);
  return response
}

//营收统计 data={user_phone:string,s_date:string,e_date:string}
export const get_income=async (data:any) => {
  const response = await fetchPost('/api/devices/tot_amount', data);
  return response
}

//USD 汇率
export const get_exchange_rate=async (data?:any) => {
  const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
  return response
}

//设备营收统计 data={user_phone:string,s_date:string,e_date:string}
export const get_devices_income=async (data:any) => {
  const response = await fetchPost('/api/devices/amount', data);
  return response
}

//历史营收统计 data={user_phone:string}
export const get_history_income=async (data:any) => {
  const response = await fetchPost('/api/devices/history_amount', data);
  return response
}

//历史营收统计 data={user_phone:string,years:number,months:number}
export const get_monthly_income=async (data:any) => {
  const response = await fetchPost('/api/devices/history_amount_mon', data);
  return response
}

